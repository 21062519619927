.series-mobile {
  padding: 12px;
  .episodeCard-mobile {
    display: flex;
    margin: 6px 0;
    .episodeImg-mobile{
      flex-basis: 40%;
      img {
        height: auto;
        width: 100%;
        max-height: 80px;
        border-radius: 8px;
      }
    }
    .episodeInfo-mobile {
      margin-left: 12px;
      flex-basis: 60%;
      .title-font {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: var(--ion-color-primary);
      }
      .description-font {
        margin-top: 4px;
        font-size: 12px;
        line-height: 16px;
        color: var(--ion-color-dark-contrast);
      }
    }
  }
}