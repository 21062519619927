.paymentsContainer {
  h3 {
    text-align: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: var(--ion-color-primary-contrast);
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
  }
}

.img-wrapper {
  max-width: 100%;
  @media (max-width: 768px) {
    max-width: 100%
  }
  .historyimg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.paymentsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 16px;
  overflow: auto;
  .paymentsListItem {
    margin-left: 20px;
    width: 30%;
    &:first-child{
      margin-left: 0;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    .paymentsListItem {
      width: 100%;
      max-width: 280px;
      margin-left: 0;
    }
  }
  .card-inside-wrap {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--ion-color-primary);
    overflow: hidden;
  }
  ion-card {
    margin: 12px 0;
    border-radius: 4px;
  }
  ion-card-subtitle {
    color: var(--ion-color-primary-contrast);
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  ion-card-header {
    padding-bottom: 8px;
  }
  .moviedetail {
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    color: var(--ion-color-primary-contrast);
    margin-bottom: 12px;
  }
  .lastseen,
  .seencount,
  .pointsearned {
    color: var(--ion-color-primary-contrast);
    font-size: 13px;
  }
  .pointsearned {
    margin-bottom: 12px;
  }
}
.paymentListItemBtnGroup {
  button {
    border: 1px solid var(--ion-color-secondary);
    background-color: transparent;
    border-radius: 4px;
    padding: 8px 10px;
    margin-left: 12px;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    transition: all 0.1s ease-in;
    color: var(--ion-color-secondary);
    font-weight: 700;
    outline: none;
    @media (max-width: 768px) {
      padding: 6px 8px;
      font-size: 11px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      background-color: var(--ion-color-secondary);
      color: #fff;
    }
  }
}

.modal-body {
  .ionPage {
    ion-content {
      --offset-top: -20px !important;
    }
  }
}
