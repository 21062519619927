@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.icon{
  cursor: pointer;
}
.castpopupwrap {
  .modal-content {
    background-color: rgba(#000, 0.8);
    .modal-title {
      font-weight: 400;
      color: #fff;
    }
    .modal-header {
      button {
        outline: none;
        span {
          font-size: 30px;
          color: #fff;
          opacity: 1;
        }
      }
    }
    .modal-body {
      max-height: calc(100vh - 140px);
      overflow: auto;
    }
  }
}
