@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
//============
//  VARIABLES
//============
$white: white;
$black: rgb(0, 0, 0);
$lightgray: lightgray;
$skyblue: lightskyblue;
//====================
//  MOVIE BACKGROUNDS
//====================
@mixin background($repeat, $position, $size) {
  background-repeat: $repeat;
  background-position: $position;
  background-size: $size;
}
* {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.one {
  background: linear-gradient(to bottom, rgba($black, 0.5), rgba($black, 0.4));
  @include background(no-repeat, center center, cover);
}
//=======================
//    MOVIE INFO
//=======================
.movie-card-long-info-block {
  width: 50%;
  background: rgba(0, 0, 0, 1);
  padding: 25px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  box-sizing: border-box;
  top: 0;
  height: 100%;
}
.movie-card-long-info {
  .card-long-title {
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
    letter-spacing: 0.4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50vw;
    overflow: auto;
  }
  .card-long-params-block {
    display: flex;
    margin-top: 30px;
    .card-long-params {
      margin-left: 57px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .card-long-description {
    margin-top: 40px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    color: #ffffff;
    letter-spacing: 0.25px;
  }
  .card-long-trailer-button {
    margin-top: 30px;
    border: none;
    width: 180px;
    height: 50px;
    background: var(--ion-color-primary);
    border-radius: 10px;
    font-family: Rubik;
    font-weight: 700;
    font-size: 13px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-long-watch-button {
    margin-top: 30px;
    border: none;
    margin-left: 30px;
    width: 180px;
    height: 50px;
    background: var(--ion-color-secondary);
    border-radius: 10px;
    font-weight: 700;
    font-size: 13px;
    line-height: 25px;
    color: #ffffff;
  }
}
ul {
  margin-top: 25px;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    letter-spacing: 0.25px;
    strong {
      font-weight: 500;
      font-size: 16px;
    }
  }
}
.movie-card-long-img {
  width: 100% !important;
  z-index: 10;
}
.owl-short .owl-stage-outer {
  height: 200px; // SAME AS CARD HEIGHT
}
@media (max-width: 1200px) {
  .card-long-description {
    font-size: 15px !important;
  }
  // ul {
  //   li {
  //     font-size: 14px !important;
  //   }
  // }
}
.section {
  width: 100%;
  // max-height: 500px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.image-gradient {
  top: 0;
  right: calc(50% - 200px);
  width: 200px;
  height: 100%;
  content: "";
  z-index: 12;
  position: absolute;
  -webkit-transition: background 0.3s ease-in;
  -o-transition: background 0.3s ease-in;
  transition: background 0.3s ease-in;
  background: -webkit-gradient(linear, left top, right top, from(#000), to(rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(left, #000, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(left, #000, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
}

.shine{
  position: relative;
  overflow: hidden;
  &:before{
    content: "";
    background: linear-gradient(118deg, #ccc 0%, #888888 50%, #333 100%);
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    min-height: 300px;
    top: 0;
    left: 0;
  } 
  &:after{
    content: "";
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 100%);
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: shimmer;
    -webkit-animation-timing-function: linear;
    z-index: 100;
    width: 150px;
    height: 200%;
    top: -92%;
    left: 0;
    transform: rotate(18deg);
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}
