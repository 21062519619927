.notfound-wrap{
	margin: 0 auto;
	max-width: 800px;
	padding: 24px;
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: calc(100vh - 170px);
	}
	img{
		width: 100%;
		max-width: 800px;
	}
	h1{
		font-size: 24px;
		line-height: 30px;
		color: #fff;
		margin-top: 70px;
		text-align: center;
		font-family: "Rubik", sans-serif;
		@media (max-width: 768px) {
			font-size: 14px;
			line-height: 20px;
			br{
				display: none;
			}
		} 
	}
	.item{
		color: #fff;
    font-size: 18px;
    text-align: center;
    margin-top: 24px;
    border-radius: 8px;
		background-color: #ccc;
		width: 100%;
		@media (max-width: 768px) {
			font-size: 14px;
		}
		ion-label{
			font-family: "Rubik", sans-serif;
			font-weight: 400;
			width: 100%;
			background-color: var(--ion-color-secondary);
			margin: 0;
			padding: 12px;
			border-radius: 8px;
			transition: all 0.2s ease-in-out;
		}
	}
}