@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800,300italic,400italic,600italic,700italic,800italic);

.movie-short-img {
  width: 100%;
  // height: 380px;
  height: 190px;
  // background-image: url(https://i.ibb.co/FDGqCmM/papers-co-ag74-interstellar-wide-space-film-movie-short-art-33-iphone6-wallpaper.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 111 !important;
  border-radius: 4px;
}

.movie-short {
  background-size: contain;
  background-size: cover;
  z-index: 1 !important;
  display: block;
  border-radius: 8px;
  margin: 0 auto;
  position: relative;
  margin-left: 2px;
  margin-right: 2px;
  overflow: hidden;
  img{
    border-radius: 8px;
    max-height: 120px;
  }
}

.movie-card{
  .cardlinking{
    @media (max-width: 767px) {
      flex: 0 0 33%;
      // min-width: 150px;
    }
    @media (max-width: 360px) {
      min-width: 150px;
    }
    .movie-short {
      margin: 0 auto;
      border-radius: 7px;
      overflow: hidden;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      img{
        width: 100%;
      }
    }
  }
}
      h5{
        font-size: 12px;
        line-height: 16px;
        margin-top: 8px;
        text-transform: capitalize;
      }
.text-movie-short-cont {
  border-radius: 0 0 8px 8px;
  padding: 4px;
  background: var(--ion-color-secondary);
  max-height: 100px;
  width: 100%;
  h5 {
    font-size: 11px;
    line-height: 15px;
    color: #ffffff;
    min-height: 45px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0px;
    margin: 0;
    li {
      font-size: 11px !important;
      line-height: 14px;
      color: #ffffff;
    }
  }
}

.action-btn {
  text-align: right;
}
.action-btn i {
  color: #fe4141;
  font-size: 28px;
  text-align: right;
}

.watch-btn {
  display: block;
  border: 1px solid #fe4141;
  border-radius: 5px;
  padding: 4px;
  width: 75px;
}
.watch-btn h3 i {
  font-size: 14px;
  margin-right: 2px;
  position: relative;
  float: left;
  line-height: 1;
}

.action-row {
  margin-top: 5px;
}

.summary-row {
  margin-top: 12px;
}

/* TYPOGRAPHY STARTS */
/* Fonts */
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  color: #e7e7e7;
  margin: 0px;
}

h1 {
  font-size: 36px;
  font-weight: 400;
}

h3 {
  font-size: 14px;
  font-weight: 400;
  color: #fe4141;
}

h5 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 8px;
}

.movie-short-likes {
  margin: 0px;
  padding: 0px;
}
.movie-short-likes li {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #818181;
  width: auto;
  display: block;
  float: left;
  margin-right: 6px;
  font-weight: 600;
}

.movie-short-likes {
  float: right;
}
.movie-short-likes li {
  color: #fe4141;
}
.movie-short-likes li:last-child {
  margin-right: 0px;
}
.movie-short-likes li i {
  font-size: 14px;
  margin-right: 4px;
  position: relative;
  float: left;
  line-height: 1;
}

.movie-short-details {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #b4b4b4;
}

.movie-short-description {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #9b9b9b;
  text-align: justify;
  line-height: 1.3;
}

.movie-short-actors {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #e7e7e7;
  font-style: italic;
}

/* TYPOGRAPHY ENDS */
/** GRID STARTS **/
.thegrid {
  margin: 0 auto;
}

.elements-distance,
.movie-short-details,
.movie-short-description,
.movie-short-actors {
  margin: 0px;
}

.mr-grid {
  width: 100%;
}

.mr-grid:before,
.mr-grid:after {
  content: "";
  display: table;
}

.mr-grid:after {
  clear: both;
}

.mr-grid {
  *zoom: 1;
}

.col1,
.col2,
.col3,
.col3rest,
.col4,
.col4rest,
.col5,
.col5rest,
.col6,
.col6rest {
  margin: 0% 0.5% 0.5% 0.5%;
  padding: 1%;
  float: left;
  display: block;
}

/* Columns match, minus margin sum. E.G. margin-left+margin-right=1%, col2=50%-1% - added padding:1%*/
.col1 {
  width: 98%;
}

.col2 {
  width: 47%;
}

.col3 {
  width: 30.3333333333%;
}

.col4 {
  width: 22%;
}

.col5 {
  width: 17%;
}

.col6 {
  width: 13.6666666667%;
}

/* Columns match with their individual number. E.G. col3+col3rest=full width row */
.col3rest {
  width: 63.6666666667%;
}

.col4rest {
  width: 72%;
}

.col5rest {
  width: 77%;
}

.col6rest {
  width: 80.3333333333%;
}

.dribbble-link {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50px;
}

.owl-short .owl-stage-outer {
  height: 200px; // SAME AS CARD HEIGHT
}

h3 {
  font-size: large;
}
