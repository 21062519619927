@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
//============
//  VARIABLES
//============
$white: white;
$black: rgb(0, 0, 0);
$lightgray: lightgray;
$skyblue: lightskyblue;
//====================
//  MOVIE BACKGROUNDS
//====================
// @mixin background($repeat, $position, $size) {
//   background-repeat: $repeat;
//   background-position: $position;
//   background-size: $size;
// }
* {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.single-movie-section-wrap {
  // background-image: url(https://private-ott.ams3.digitaloceanspaces.com/16-9-banner-example.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0;
    align-items: flex-end;
  }
}
.movie-info-wrapper {
  margin: 10px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
  display: grid;
  grid-template-columns: 500px auto;
  background-color: #0a0b0d;
  align-items: center;
  .movie-card-long-img {
    // width: calc(100% - 500px) !important;
    z-index: 10;
  }
  @media (max-width: 1220px) {
    grid-template-columns: 440px auto;
  }
  .video-wrap{
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 0;
    position: relative;
    .movie-card-long-video {
      width: 100%;
      object-fit: cover;
    }
    .mute-btn{
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
      ion-icon{
        font-size: 25px;
      }
    }
  }
}

.seprator {
  height: 4px;
  width: calc(100% - 20px);
  background-color: var(--ion-color-primary);
  margin: 10px;
  border-radius: 8px;
}

.big-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  &:after{
    content: "";
    height: 100%;
    width: 100px;
    position: absolute;
    right: -100px;
    z-index: 2;
    background: rgb(5,5,6);
    background: linear-gradient(90deg, rgba(5,5,6,1) 0%, transparent 100%);
  }
  .movie-details-box {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      padding: 15px;
    }
    .movie-description-wrap {
      overflow: auto;
      max-height: 260px;
      // max-height: calc(100% - 190px);
      transition: all 0.4s ease;
      @media (max-width: 1680px) and (min-width: 1441px) {
        max-height: 400px;
      }
      @media (max-width: 1390px) {
        max-height: 220px;
      }
      @media (max-width: 1330px) {
        max-height: 190px;
      }
      @media (max-width: 1270px) {
        max-height: 160px;
      }
      @media (max-width: 1220px) {
        max-height: 150px;
      }
      @media (max-width: 1220px) {
        max-height: 130px;
      }
    }
    .movie-title {
      font-size: 28px;
      color: #fff;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0.5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @media (max-width: 1680px) and (min-width: 1441px) {
        font-size: 32px;
        line-height: 50px;
      }
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .movie-ratings-duration-wrap {
      .movie-rating-duration-list {
        display: flex;
        margin-top: 15px;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          margin-top: 15px;
        }
        .list {
          font-weight: 500;
          cursor: pointer;
            &:not(:first-child){
            &:before{
              content: "•";
              margin-right: 12px;
              margin-left: 12px;
              color: var(--ion-color-primary);
              font-size: 16px;
            }
          }
          @media (max-width: 768px) {
            margin-left: 12px;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .movie-desc,
    .movie-starcast {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      color: #ffffff;
      @media (max-width: 768px) {
        margin-top: 15px;
      }
      @media (max-width: 1680px) and (min-width: 1441px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .movie-starcast {
      ul {
        li {
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
          strong {
            font-weight: 500;
          }
        }
      }
    }
  }
  .action-btn-wrap {
    margin-top: 30px;
    @media (max-width: 768px) {
      margin-top: 15px;
    }
    .btn {
      border: none;
      border-radius: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: #ffffff;
      cursor: pointer;
      margin: 0;
      letter-spacing: 1px;
      outline: 0;
      .play-icon{
        width: 12px;
        margin-right: 12px;
      }
      &:hover{
        transform: scale(1.08);
      }
      @media (max-width: 768px) {
        width: auto;
      }
      &.btn-primary {
        background: var(--ion-color-primary);
      }
      &.btn-secondary {
        background: var(--ion-color-secondary) !important;
      }
    }
  }
}
.movie-detail-icons {
  display: flex;
  align-items: center;
  margin-top: 20px;
  ion-icon {
    font-size: 21px;
  }
  .socialiconswrap {
    position: relative;
    cursor: pointer;
    margin-right: 12px;
    line-height: 9px;
    .btnsdrawer {
      position: absolute;
      bottom: calc(100% + 8px);
      right: calc(-50% - 3px);
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      &.show {
        max-height: 400px;
      }
      svg {
        width: 50px;
        height: 50px;
        @media (max-width: 768px) {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  .likeicon{
    cursor: pointer;
  }
}
.mtop{
  margin-top: auto;
}