@import "../../../../assets/css/themes/normal.scss";

.filter-btn-wrap{
	padding: 8px 0;
	.filter-tabs{
		display: flex;
		overflow: auto;
		padding: 8px 16px;
		margin: 0;
		li{
			display: block;
			background: #161C21;
			box-shadow: 0px 0px 3.36449px #000000;
			border-radius: 6px;
			text-align: center;
			padding: 8px 16px;
			font-size: 12px;
			line-height: 16px;
			white-space: nowrap;	
			cursor: pointer;
			transition: all 0.15s ease-in;
			min-width: 70px;
			flex-grow: 1;
    	width: calc(100%/3);
			&:not(:first-child){
				margin-left: 10px;
			}
			a{
				color: var(--ion-color-primary-contrast);
			}
			&.active{
				background-color: var(--ion-color-primary);
				color: var(--ion-color-secondary);
				a{
					color: var(--ion-color-black);
				}
			}
		}
	}
}