@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.castpopupwrap {
  .castpopup-body-wrap {
    .castimg-wrap {
      position: relative;
      margin: 15px 0;
      border-radius: 8px;
      overflow: hidden;
      max-height: 170px;
      img {
        width: 100%;
      }
      figcaption {
        position: absolute;
        bottom: 0;
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.5);
        color: var(--ion-color-dark-contrast);
        padding: 12px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
