@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800,300italic,400italic,600italic,700italic,800italic);

.movie-normal-img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 111 !important;
  border-radius: 8px;
  height: 266px;
  @media (min-width: 1441px) {
    height: 305px;
  }
}

.movie-normal {
  margin: 0 auto;
  border-radius: 7px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
  &:hover{
    transform: scale(1.04);
  }
  .cardimg{
    max-height: 140px;
    border-radius: 8px;
  }
}
.movie-card{
  display: flex;
  grid-row-gap: 24px;
  grid-column-gap: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
  .cardlinking{
    flex: 0 0 calc(20% - 8px);
    min-width: 180px;
    .movie-normal {
      border-radius: 7px;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      img{
        width: 100%;
      }
    }
  }
}
.cardlinking{
  &:hover{
    text-decoration: none;
  }
}
.text-movie-normal-cont {
  .movie-normal-title {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #ffffff;
  }
  ul {
    list-style: none;
    padding: 0px;
    display: flex;
    margin: 0;
    margin-top: 5px;
    li {
      margin-left: 3px;
      font-weight: 300;
      font-size: 9px;
      color: #ffffff;
    }
  }
}

.action-btn {
  text-align: right;
}
.action-btn i {
  color: #fe4141;
  font-size: 28px;
  text-align: right;
}

.watch-btn {
  display: block;
  border: 1px solid #fe4141;
  border-radius: 5px;
  padding: 4px;
  width: 75px;
}
.watch-btn h3 i {
  font-size: 14px;
  margin-right: 2px;
  position: relative;
  float: left;
  line-height: 1;
}

.action-row {
  margin-top: 5px;
}

.movie-normal-details {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #b4b4b4;
}

.movie-normal-description {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #9b9b9b;
  text-align: justify;
  line-height: 1.3;
}

.movie-normal-actors {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #e7e7e7;
  font-style: italic;
}

.elements-distance,
.movie-normal-details,
.movie-normal-description,
.movie-normal-actors {
  margin: 0px;
}

.dribbble-link {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50px;
}

.owl-normal .owl-stage-outer {
  height: 250px; // SAME AS CARD HEIGHT
}

.slick-slide img{
  max-width: 100%;
}
.flag {
  margin: 6px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--ion-color-secondary);
  position: absolute;
  padding: 4px;
  box-shadow: -2px 0 6px 2px var(--ion-color-dark);
  @media (max-width: 475px) {
    width: 20px;
    height: 20px;
  }
}