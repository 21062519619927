@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;700&display=swap");

.planpayment {
  .modal-content {
    min-height: 410px;
    background-color: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast);
    @media (max-width: 768px) {
      min-height: calc(100vh - 20px);
    }
  }
}
.planpayment-popup {
  color: var(--ion-color-dark-contrast);
  background-color: var(--ion-color-dark);
  padding: 0 24px;
  font-family: "Rubik", sans-serif;
  height: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 60px);
    justify-content: center;
  }
  .main-heading {
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    &-sub {
      font-size: 12px;
      line-height: 16px;
      margin-top: 12px;
      font-weight: 300;
    }
    &-container {
      ion-button {
        margin-top: 12px;
        text-transform: capitalize;
      }
      .align-center {
        text-align: center;
      }
    }
  }
  .plans-container {
    width: 100%;
    .plan-heading {
      font-size: 24px;
      line-height: 30px;
      font-weight: 400;
      margin-bottom: 12px;
      margin-top: 24px;
    }
    .plan-box-wrap {
      display: flex;
      grid-gap: 16px;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        grid-gap: 0;
      }
      .plan-box {
        border: 1px solid var(--ion-color-primary);
        padding: 8px 16px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 50%;
        transition: all 0.3s ease-in;
        @media (max-width: 767px) {
          flex: 1 1 100%;
          &:not(:first-child){
            margin-top: 16px;
          }
        }
        .pricing {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          span {
            color: var(--ion-color-secondary);
          }
        }
        ion-button {
          margin-top: 16px;
          text-transform: capitalize;
        }
        &:hover {
          box-shadow: var(--ion-color-primary);
        }
      }
    }
  }
  .disclaimer {
    font-size: 10px;
    line-height: 14px;
    margin-top: 12px;
    font-weight: 300;
  }
}
.otp {
  height: 38px;
  width: 75%;
  margin-bottom: 10px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  padding: 2px 10px;
  color: var(--ion-color-primary);
  background-color: var(--ion-color-dark) !important;
  outline: none;
  margin-top: 24px;
  letter-spacing: 15px;
  text-align: center;
  &::placeholder {
    font-weight: 500;
    font-size: 12px;
    color: var(--ion-color-primary);
    letter-spacing: 0;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--ion-color-dark) inset !important;
    -webkit-text-fill-color: var(--ion-color-primary);
  }
}
