.profileContainer {
  min-height: 100%;
  display: flex;
  justify-content: center;
  // background: rgba(0, 0, 0, 0.75);
}
@import "../../../../../src/assets/css/themes/normal.scss";

.userProfile {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  .sb-avatar--text span {
    font-size: 28px;
  }
  .userProfileHeader {
    text-align: center;
    margin: 20px;
    h3 {
      font-size: 20px;
      color: var(--ion-color-secondary-contrast);
      margin-bottom: 12px;
    }
    img {
      margin-top: 15px;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
  }
  .infoBlock {
    width: 80%;
    @media (max-width: 768px) {
      width: 90%;
    }
    .infoBlockRow {
      margin-top: 12px;
      padding: 10px 12px;
      border: 1px solid var(--ion-color-primary);
      border-radius: 5px;
      display: flex;
      align-items: center;
      grid-gap: 16px;
      &:first-child {
        margin-top: 0;
      }
      .infoType,
      .infoValue {
        font-weight: 500;
        font-size: 15px;
        color: var(--ion-color-primary);
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .infoType {
        width: 40%;
      }
      .infoValue {
        width: 60%;
        word-break: break-word;
        .btnprimary {
          margin: 0;
          width: 100%;
          height: 30px;
          font-size: 12px;
        }
        ion-button {
          font-weight: 700;
          text-transform: capitalize;
        }
      }
    }
  }
  a {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    color: var(--ion-color-secondary);
  }
}

.cancel-popup {
  &-body{
    padding: 16px;
    counter-reset: section;
  }
  &-wrap {
    padding: 10px;
    .modal-wrapper {
      background-color: var(--ion-color-dark);
    }
    .logoimg {
      margin: 0 auto;
      max-width: 120px;
      img {
        width: 100%;
      }
    }
    .steps-wrapper {
      li {
        color: var(--ion-color-primary-contrast);
        font-size: 16px;
        line-height: 24px;
        &:not(:first-child){
          margin-top: 8px;
        }
        span {
          color: var(--ion-color-primary);
          &::after{
            counter-increment: section;
            content: counter(section) ":";
          }
        }
      }
    }
    .note {
      color: var(--ion-color-primary-contrast);
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      display: block;
    }
  }
}
