.settingsContainer {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 2% 0;
}
.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  h3 {
    margin-top: 20px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: var(--ion-color-primary);
  }
  .infoBlock {
    width: 80%;
    margin-top: 50px;
    .infoBlockRow {
      margin-top: 32px;
      height: 46px;
      border: 1px solid var(--ion-color-primary);
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      .infoType {
        flex: 0 0 40%;
        font-weight: 500;
        font-size: 15px;
        color: var(--ion-color-primary);
      }
      .infoValue {
        flex: 0 0 60%;
        font-weight: 500;
        font-size: 15px;
        color: var(--ion-color-primary);
      }
      select {
        background-color: transparent;
        border: 1px solid var(--ion-color-primary);
        border-radius: 2px;
        padding: 3px 8px;
        outline: none;
      }
    }
  }
  a {
    font-weight: 500;
    font-size: 15px;
    color: var(--ion-color-primary);
  }
}
ion-toggle {
  --background: #ccc;
  --background-checked: var(--ion-color-primary);
  --handle-background: var(--ion-color-secondary);
  --handle-background-checked: var(--ion-color-secondary);
}
@media (max-width: 768px) {
  .settings {
    width: 100%;
    h3 {
      margin-top: 30px;
    }
    .infoBlock {
      width: 90%;
      margin-top: 30px;
      .infoBlockRow {
        margin-top: 24px;
        .infoType,
        .infoValue {
          font-size: 14px;
        }
      }
    }
    a {
      font-size: 14px;
    }
  }
}
