.authPage-mobile {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  .signInHeader-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    img {
      max-height: 120px;
    }
    .authBtnGroup-mobile {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
    .authBtn-active-mobile,
    .authBtn-mobile {
      font-weight: 500;
      font-size: 18px;
      width: 100%;
      border-radius: 5px;
    }
  }
  .authForm-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    .input-group {
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
    }
    .input-control {
      border: 2px solid var(--ion-color-primary);
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 10px;
      color: var(--ion-color-primary);
      background-color: var(--ion-color-dark) !important;
      outline: none;
      width: 100%;
      box-sizing: border-box;
      &::placeholder {
        font-weight: 500;
        font-size: 12px;
        color: rgba(var(--ion-color-primary), 0.9);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--ion-color-dark) inset !important;
        -webkit-text-fill-color: var(--ion-color-primary);
      }
    }
    .signbtn {
      font-size: 18px;
      font-weight: 500;
    }
    .error {
      font-size: 12px;
      line-height: 18px;
    }
    .signWithSocial-mobile {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .socialbtn {
        background: var(--ion-color-primary);
        border: 1px solid var(--ion-color-primary);
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 12px;
        color: var(--ion-color-primary);
        text-transform: unset;
        .socialicon {
          margin-left: 2px;
          width: 15px;
        }
      }
      ion-button {
        flex: 1 1 50%;
      }
    }
    .forgotPassword-mobile {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      text-transform: uppercase;
      color: var(--ion-color-primary);
    }
    .privacypolicy {
      display: flex;
      align-items: center;
      a {
        color: var(--ion-color-primary);
        font-weight: 600;
        font-size: 12px;
        margin-left: 12px;
      }
    }
    .countrycode {
      background-color: transparent;
      border: 2px solid var(--ion-color-primary);
      min-width: 160px;
      outline: 0;
      font-size: 12px;
      font-weight: 700;
      border-radius: 4px;
      .css-yk16xz-control,
      .css-1pahdxg-control {
        background-color: transparent;
        border: none;
        outline: 0;
        box-shadow: none;
        .css-1wa3eu0-placeholder {
          color: var(--ion-color-primary);
          font-size: 12px;
        }
        .css-1uccc91-singleValue {
          color: var(--ion-color-primary);
        }
        .css-1okebmr-indicatorSeparator { 
          background-color: var(--ion-color-primary);
        }
        .css-tj5bde-Svg {
          fill: var(--ion-color-primary);
        }
        .css-b8ldur-Input {
          color: var(--ion-color-primary);
        }
      }
      .css-26l3qy-menu {
        height: 160px;
        overflow: hidden;
      }
    }
    .numbercode-wrap {
      .countrycode {
        background-color: transparent;
        // color: rgba(239, 207, 2, 0.9);
        border: none;
        border-right: 2px solid var(--ion-color-primary);
        min-width: 160px;
        outline: 0;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        border-radius: 0;
      }
      .input-control {
        padding-left: 170px;
      }
    }
  }
}
