@import "../../../../assets/css/themes/normal.scss";

.buttons-first-slot,
[slot*="start"] {
	width: 100%;
	padding: 6px 0 6px 12px;
	a {
		font-size: 16px !important;
		color: var(--ion-color-primary) !important;
		border-bottom: 1px solid transparent;
		position: relative;
		text-decoration: none;
		&:first-child {
			padding-right: 40px;
		}
		&:not(:first-child) {
			margin: 0 20px;
		}
		&:after {
			content: "";
			width: 0;
			height: 2px;
			position: absolute;
			bottom: -4px;
			background-color: var(--ion-color-primary);
			left: 0;
			transition: all 0.2s ease-in-out;
		}
		&:first-child {
			&:after {
				content: "";
				height: 0 !important;
			}
		}
		&:hover {
			text-decoration: none;
			&:after {
				width: 100%;
			}
		}
	}
	ion-menu-button {
		font-size: 30px;
	}
	.page-title {
		width: 100%;
		align-items: center;
		justify-content: center;
		position: absolute;
		display: flex;
		font-size: 16px;
		line-height: 20px;
		font-weight: 600;
		letter-spacing: 1px;
		color: var(--ion-color-primary);
	}
}

.buttons-last-slot,
[slot*="end"] {
	padding: 6px 12px 6px 0;
	@media (min-width: 768px) {
		flex-direction: row-reverse;
	}
	@media (max-width: 768px) {
		ion-icon {
			font-size: 24px;
		}
		.sb-avatar {
			margin-left: 12px;
		}
	}
}
.sb-avatar--text span {
	font-size: 18px;
}
.profile-details {
	display: flex;
	align-items: center;
	margin-right: 24px;
	margin-left: 24px;
	cursor: pointer;
	@media (min-width: 480px) {
		margin-right: 0;
		margin-left: 18px;
	}
	.profile-name {
		position: relative;
		font-weight: 500;
		color: #05396b;
		margin-left: 8px;
		margin-right: 12px;
		&:after {
			content: "";
			position: absolute;
			top: 6px;
			right: -22px;
			border-top: 7px solid #05396b;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
		}
	}
}

.searchbar-wrap {
	position: relative;
	.searchbar {
		height: 36px;
		border: 2px solid var(--ion-color-primary);
		color: var(--ion-color-primary);
		border-radius: 10px;
		width: 180px;
		padding: 4px 12px;
		box-sizing: border-box;
		outline: none;
		padding-right: 32px;
		transition: all 0.2s cubic-bezier(0.5, 0, 0.2, 1);
		background-color: transparent;
		&::placeholder {
			color: var(--ion-color-primary);
		}
		&:focus {
			width: 300px;
		}
	}
	ion-icon {
		position: absolute;
		top: 8px;
		right: 8px;
		width: 20px;
		cursor: pointer;
	}
	@media (max-width: 1025px) {
		display: none;
	}
}
.searchbar-input-container {
	.searchbar-input {
		border: 2px solid var(--ion-color-primary);
		border-radius: 10px;
		color: var(--ion-color-dark-contrast);
	}
}
.item-wrap {
	display: flex;
	padding: 0 14px;
	align-items: center;
	cursor: pointer;
	img {
		width: 20px;
	}
}
ion-list-header {
	padding: 0;
	font-size: 16px;
	font-weight: 500;
}
.popover-content {
	top: 57px !important;
	padding: 0;
	left: unset !important;
	right: 16px;
	width: 280px !important;
}

.user-details-wrap {
	display: flex;
	align-items: center;
	background-color: var(--ion-color-dark);
	padding: 10px;
	.user-details {
		display: flex;
		flex-direction: column;
		margin-top: -10px;
		padding-left: 8px;
		color: var(--ion-color-secondary-contrast);
		.email {
			font-size: 12px;
			font-weight: 300;
			margin-top: -14px;
		}
	}
	ion-icon {
		font-size: 50px;
		flex-shrink: 0;
	}
}

ion-list-header {
	color: var(--ion-color-primary);
}
.logolink {
	&:after {
		content: "";
		height: 0 !important;
	}
	@media (max-width: 767px) {
		text-align: center;
	}
	.logoimg {
		max-width: 90px;
	}
}
.download-app-bar {
	display: none !important;
}
@media (max-width: 768px) {
	.download-app-bar {
		padding: 9px 24px 9px 32px;
		display: flex !important;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;
		background-color: var(--ion-color-dark);
		--ion-background-color: var(--ion-color-dark);
		--background: var(--ion-color-dark);

		.close-icon {
			&:before {
				content: "";
				height: 12px;
				width: 2px;
				position: absolute;
				border-radius: 4px;
				background-color: var(--ion-color-dark-contrast);
				top: 6px;
				left: 10px;
				transform: rotate(45deg);
			}
			&:after {
				content: "";
				height: 12px;
				width: 2px;
				position: absolute;
				border-radius: 4px;
				background-color: var(--ion-color-dark-contrast);
				top: 6px;
				left: 10px;
				transform: rotate(-45deg);
			}
		}
		.app-bar-left {
			display: flex;
			align-items: center;
			.app-bar-logo {
				img {
					max-width: 40px;
				}
			}
			.app-bar-text {
				margin-left: 8px;
				.main-text {
					font-size: 18px;
					line-height: 22px;
					font-weight: 400;
					color: var(--ion-color-dark-contrast);
				}
				.semi-text {
					font-size: 12px;
					line-height: 16px;
					font-weight: 400;
					margin-top: 2px;
					color: var(--ion-color-dark-contrast);
				}
			}
		}
		.app-bar-right {
			.forward-icon {
				display: block;
				width: 18px;
				height: 18px;
				border-radius: 2px;
				transform: rotate(-45deg);
				border-right: 3px solid var(--ion-color-dark-contrast);
				border-bottom: 3px solid var(--ion-color-dark-contrast);
			}
		}
	}
}
