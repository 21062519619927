@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
@import "../../../../assets/css/themes/normal.scss";
//============
ion-header {
	background-color: var(--ion-color-primary);
}
ion-menu {
	background: rgba(var(--ion-color-secondary), 0.5) !important;
}
ion-content {
	--ion-background-color: #fff;
}
.nameBlock {
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	h3 {
		font-weight: 400;
		margin-bottom: 5px;
		letter-spacing: 0.15px;
		color: var(--ion-color-primary-contrast);
	}
	#mail {
		font-weight: 300;
		font-size: 12px;
		letter-spacing: 0.15px;
		color: var(--ion-color-primary-contrast);
		word-break: break-all;
	}
}

.sideMenuNav {
	height: calc(100vh - 200px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.sideMenuLabel {
		color: var(--ion-color-primary);
		margin-left: 12px;
	}
	.sideMenuNavItem {
		display: flex;
		align-items: center;
		img {
			height: 20px;
			width: 20px;
			margin-right: 10px;
		}
	}
}

.tollbar-header{
	display: flex;
	padding: 16px;
}

.sideMenuNavSecondBlock {
	.sideMenuSmallLabel {
		font-weight: 300;
		line-height: 21px;
		letter-spacing: 0.1px;
		color: var(--ion-color-primary);
	}
}
.list-md {
	padding: 0;
}
