@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import "../../../../assets/css/vars.scss";
.movieInfoContainer {
  font-family: Rubik;
  background-color: var(--ion-color-dark);
  .titleBlock {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title {
      margin-top: 30px;
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 85px;
      line-height: 55px;
      color: $brand-main;
      text-transform: capitalize;
    }
    .information {
      margin-top: 40px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 43px;

      color: $brand-main;
    }
    button {
      .play-icon {
        height: 30px;
        width: 30px;
        margin-right: 30px;
      }
      margin-top: 30px;
      border: none;
      background: var(--ion-color-secondary);
      border-radius: 10px;
      width: 90%;
      height: 140px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 55px;
      line-height: 76px;

      color: #ffffff;
    }
  }

  .descriptionBlock {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    p {
      width: 90%;
      margin-top: 40px;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 57px;

      color: var(--ion-color-dark-contrast);
    }
    .cast-title {
      font-size: 30px;
      line-height: 43px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--ion-color-dark-contrast);
    }
  }

  .recommendation-title {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 56px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 76px;
    color: $brand-main;
  }
}

@media (max-width: 1024px) {
  .movieInfoContainer {
    .titleBlock {
      font-size: 32px;
      padding: 0 16px;
      margin: 0 auto;
      a {
        width: 100%;
        display: flex;
      }
      .title {
        margin-top: 10px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: $brand-main;
      }
      .movie-details-wrap {
        margin: 0;
        padding: 0;
        display: flex;
        margin-top: 10px;
        overflow: auto;
        .movie-details-list {
          color: var(--ion-color-dark-contrast);
          font-size: 12px !important;
          font-weight: 500;
          line-height: 20px;
          display: flex !important;
          white-space: nowrap;
          &:not(:first-child){
            &:before{
              content: "•";
              margin-right: 5px;
              margin-left: 5px;
              color: var(--ion-color-primary);
              font-size: 16px;
            }
          }
        }
      }
      ion-button{
        font-size: 16px;
        font-weight: 700;
        height: 36px;
      }
    }

    .descriptionBlock {
      display: flex;
      flex-direction: column;
      margin: 0 16px;
      p {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 10px;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: var(--ion-color-dark-contrast);
      }
      .cast-title {
        font-size: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--ion-color-dark-contrast);
      }
    }
    .recommendation-title {
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 10px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 15px;

      color: $brand-main;
    }
  }
}

.one {
  position: relative;
  overflow: hidden;
}

:root {
  --ion-color-primary: #efcf02;
  --ion-color-secondary: #eb1e4e;
}
.btnprimary {
  width: 100%;
  margin: 0;
  margin-top: 16px;
  height: 32px;
  img {
    width: 12px;
    margin-right: 10px;
  }
}
@media (max-width: 768px) {
  .cast-card-slider-wrap {
    padding: 0 16px;
    .slick-slider .slick-list, .slick-slider .slick-track{
      display: flex;
    }
    .slick-track{
      min-width: fit-content;
    }
    .slick-prev:before,
    .slick-next:before {
      color: var(--ion-color-primary);
      font-size: 22px;
    }
    .slick-next {
      right: -19px !important;
      top: 60px;
    }
    .slick-prev {
      left: -20px !important;
      top: 60px;
    }
  }
}