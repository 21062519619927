@import "../../../../assets/css/vars.scss";

.section{
	width: 100%;
    margin: 10px;
    border-radius: 12px;
    overflow: hidden;
}
.carosuel-icon {
	color: var(--ion-color-primary);
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 10px;
	font-weight: 400;
	font-family: 'Rubik', sans-serif;
}

.border-bottom-big{
	.slick-arrow{
		display: none !important;
	}
	.movie-detail-icons,
	.movie-card-long-video{
		display: none;
	}
}

.carousal-container {
	width: 94%;
	padding: 20px;
	@media (max-width: 768px) {
		width: 95%;
		padding: 20px 6px;
	}
	.slick-prev:before, .slick-next:before{
		color: var(--ion-color-primary);
		font-size: 25px;
	}
	@media (max-width: 900px) {
		.slick-prev{
			display: none !important;
		}
		.slick-next{
			display: none !important;
		}
	}
}

.skeleton-card-normal {
	width: 70px;
	height: 180px;
	padding-left: 10px;
	padding-right: 10px;
}
.skeleton-card-short {
	width: 110px;
	height: 190px;
	padding-left: 10px;
	padding-right: 10px;
}
.skeleton-card-long-mobile {
	height: 200px;
	padding-left: 10px;
	padding-right: 10px;
}
.skeleton-card-long {
	height: 310px;
	padding-left: 10px;
	padding-right: 10px;
}

.slick-track{ margin: 0 !important; display: flex; align-items: center; }

.border-bottom-big{
	@media (min-width: 769px) {
		.slick-slide{
			transform: scale(0.95);
			opacity: 0.5;
			transition: all 0.5s ease-in-out;
			&.slick-center{
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}